import styled from '@emotion/styled';

import { lightenDarkenColor } from 'utils/colors';

import vars from 'theme/styles/vars';

export default styled.div`
	width: 100%;
	position: relative;
	padding: 4em 0 6em 0;
	background-color: ${vars.colorPrimaryDark};

	.columns {
		display: flex;
		justify-content: space-between;
		align-items: flex-start;

		.column-logo {
			position: relative;
			width: 10em;
		}

		.column-nav {
			display: flex;
			flex-flow: column wrap;
			justify-content: center;
			align-items: flex-start;

			.nav-link {
				margin: 0.3em 0;
				color: ${vars.colorTextWhite};

				&:hover {
					color: ${lightenDarkenColor(vars.colorTextDark, 100)};
				}

				&:last-of-type {
					margin-right: 0;
				}
			}
		}

		.column-social {
			.social-links {
				display: flex;
				gap: 0.6em;

				.social-link {
					.social-link-icon {
						width: 3em;
						height: 3em;
						background-color: ${vars.colorPrimaryBlue};
						transition: 0.2s ease-in-out background-color;

						&:hover {
							background-color: ${vars.colorSecondaryBlue};
						}
					}
				}
			}
		}
	}

	.bottom {
		border-top: 0.02em solid #27293c;
		padding: 2em 0 0 0;
		margin: 2em 0 0 0;
		color: ${vars.colorTextLight};
		display: flex;
		justify-content: space-between;

		.bottom-links {
			color: ${vars.colorTextWhite};
			display: flex;
			gap: 1.5em;
		}
	}

	@media all and (max-width: ${vars.mobileL}) {
		.columns {
			display: flex;
			flex-flow: column;
			gap: 3em;
			font-size: 1.3em;
			margin: 0 2em;
		}
		.bottom {
			margin: 3em 2em;
			flex-flow: column wrap;
			gap: 2em;
		}
	}
`;
