import { Component, ReactNode } from 'react';
import DisplayNavProvider from 'providers/hiddenNav';

import WrapperPublic from 'components/wrappers/Public';

import { Props } from './index';
import StyledComponent from './styles';


export default class WrapperApp extends Component<Props> {
    static defaultProps = {
        children: null,
    };


    render = (): ReactNode => {
        const { children } = this.props;

        return (
            <StyledComponent className="wrapper-app">
                <DisplayNavProvider>
                    <WrapperPublic>
                        {children}
                    </WrapperPublic>
                </DisplayNavProvider>
            </StyledComponent>
        );
    };
}
