import { FunctionComponent, useEffect, useState } from 'react';

import { Routes } from 'types/routes';

import { getProvider } from 'services/Cookies';

import Button from 'components/layout/Button';
import LayoutContainer from 'components/layout/LayoutContainer';
import Link from 'components/layout/Link';

import StyledComponent from './styles';
import { Props } from './types';

const WrapperPublicBlocksCookiebar: FunctionComponent<Props> = () => {
    const [isVisible, setIsVisible] = useState(false);
    const provider = getProvider();

    useEffect(() => {
        if(!provider.get('cookie-agree')) {
            setIsVisible(true);
        }
    }, []);

    if(!isVisible) return null;
    return (
        <StyledComponent className="wrapper-public-blocks-cookiebar">
            <LayoutContainer>
                <div className="columns">
                    <div className="column-text">
                        Nasz Serwis wykorzystuje pliki cookies w celu korzystania z narzędzi analitycznych czy marketingowych.
                        <br />
                        Kliknij przycisk "Zgadzam się", aby zaakceptować wszystkie pliki cookie i przejść bezpośrednio na stronę internetową.
                        <br />
                        W naszej <Link href={Routes.PublicPrivacyPolicy}>Polityce Prywatności</Link> możesz znaleźć więcej informacji na temat stosowanych narzędzi i wykorzystania Twoich danych, które my i nasi partnerzy gromadzimy za pomocą plików cookie.
                    </div>
                    <div className="column-button">
                        <Button
                            onClick={() => {
                                provider.set('cookie-agree', 'true');
                                setIsVisible(false);
                            }}
                            ariaLabel="Zgadzam się na politykę prywatności"
                        >
                            Zgadzam się
                        </Button>
                    </div>
                </div>
            </LayoutContainer>
        </StyledComponent>
    );
};

export default WrapperPublicBlocksCookiebar;
