export enum Routes {
    // Portal routes
    PublicHome = '/',

    PublicHomeAbout = '/#about',
    PublicHomeContact = '/#contact',
    PublicHomeSevices = '/#services',

    PublicCompanyRegistrationForm = '/rejestracja-firmy',
    PublicPasswordReset = '/zmien-haslo',

    PublicRegulations = '/regulamin',
    PublicPrivacyPolicy = '/polityka-prywatnosci',

}