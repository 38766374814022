import styled from '@emotion/styled';

import vars from 'theme/styles/vars';

export default styled.div`
	width: 100%;
	position: fixed;
	z-index: 9999;
	left: 0;
	bottom: 0;
	background-color: ${vars.colorPrimaryDark};
	padding: .5em 0;

	.columns {
		display: flex;
		justify-content: space-between;
		align-items: center;

		.column-text {
			color: #EEEEEE;
			font-size: .7em;

			a {
				text-decoration: underline;
			}

			* {
				display: inline;
			}
		}

		.column-button {
			font-size: 70%;
		}
	}

	@media all and (max-width: ${vars.mobileL}) {
	}
`;
