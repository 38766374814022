import { FunctionComponent, useState } from 'react';

import StyledComponent from './styles';
import { Props } from './types';

const WrapperPublicBlocksEuropeanUnionBar: FunctionComponent<Props> = () => {
    const [isVisible, setIsVisible] = useState(true);

    if(!isVisible) return null;
    return (
        <StyledComponent className="wrapper-public-blocks-european-union-bar">
            <img
                className="image"
                src='./images/europe-bar/europe.jpg'
            />
            <div
                className="close"
                onClick={() => setIsVisible(false)}
            >
                <img
                    className="close-icon"
                    src='./icons/close.svg'
                />
            </div>
        </StyledComponent>
    );
};

export default WrapperPublicBlocksEuropeanUnionBar;
