import styled from '@emotion/styled';

import vars from 'theme/styles/vars';

export default styled.h3`
	position: relative;
	color: ${vars.colorSecondaryBlue};
	font-family: ${vars.fontSecondary};
	text-transform: uppercase;
	letter-spacing: 2px;
	font-weight: 400;
	margin: 0 0 1em 0;
`;
