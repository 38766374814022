import styled from '@emotion/styled';

import vars from 'theme/styles/vars';

export default styled.div`
	position: relative;
	padding-top: 3em;

	.wrapper-public-blocks-topbar-desktop {
		display: block;
	}
	.wrapper-public-blocks-topbar-mobile {
		display: none;
	}

	&.disabled-padding {
		padding: 0;
	}

	@media all and (max-width: ${vars.tabletS}) {
		.wrapper-public-blocks-topbar-desktop {
			display: none;
		}
		.wrapper-public-blocks-topbar-mobile {
			display: block;
		}
	}
`;
