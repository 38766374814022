import { FunctionComponent, useContext } from 'react';
import getConfig from 'next/config';
import { GoogleOAuthProvider } from '@react-oauth/google';
import classNames from 'classnames';
import { HiddenNavContext } from 'providers/hiddenNav';

import Cookiebar from './blocks/Cookiebar';
import EuropeanUnionBar from './blocks/EuropeanUnionBar';
import Footer from './blocks/Footer';
import Topbar from './blocks/Topbar';
import TopbarMobile from './blocks/TopbarMobile';
import { Props } from './index';
import StyledComponent from './styles';

const WrapperPublic: FunctionComponent<Props> = ({ children }) => {
    const [isHidden]: [boolean] = useContext(HiddenNavContext);

    const { publicRuntimeConfig } = getConfig();

    return (
        <>
            <StyledComponent className={classNames('wrapper-public', {
                'disabled-padding': Boolean(isHidden),
            })}
            >
                <GoogleOAuthProvider clientId={publicRuntimeConfig.GOOGLE_CLIENT_ID}>
                    <Topbar
                        className={classNames({
                            'hidden-nav': Boolean(isHidden),
                        })}
                    />
                    <TopbarMobile
                        className={classNames({
                            'hidden-nav': Boolean(isHidden),
                        })}
                    />
                    <div className="wrapper-content">
                        {children}
                    </div>
                    <Footer />
                    <Cookiebar />
                    <EuropeanUnionBar />
                </GoogleOAuthProvider>
            </StyledComponent>
        </>
    );
};

export default WrapperPublic;