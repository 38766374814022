import { FunctionComponent } from 'react';

import { Routes } from 'types/routes';

import FbIcon from 'theme/icons/social/facebook.svg';
import TwIcon from 'theme/icons/social/instagram.svg';
import InstaIcon from 'theme/icons/social/twitter.svg';

import IconCircle from 'components/layout/IconCricle';
import LayoutContainer from 'components/layout/LayoutContainer';
import Link from 'components/layout/Link';
import Logo from 'components/layout/Logo';
import Title from 'components/layout/Title';

import StyledComponent from './styles';
import { Props } from './types';

const WrapperPublicBlocksFooter: FunctionComponent<Props> = () => ((
    <StyledComponent className="wrapper-public-blocks-footer">
        <LayoutContainer>
            <div className="columns">
                <div className="column-logo">
                    <Logo />
                </div>
                <nav className="column-nav">
                    <Title title="Menu" />
                    <Link
                        className="nav-link"
                        href={Routes.PublicHomeSevices}
                    >
                        Dlaczego FizjoAI?
                    </Link>
                    <Link
                        className="nav-link"
                        href={Routes.PublicHomeAbout}
                    >
                        Możliwości
                    </Link>
                    <Link
                        className="nav-link"
                        href={Routes.PublicHomeContact}
                    >
                        Kontakt
                    </Link>
                </nav>
                <nav className="column-nav">
                    <Title title="Dla firm" />
                    <Link
                        className="nav-link"
                        href={Routes.PublicCompanyRegistrationForm}
                        prefetch={false}
                    >
                        Formularz rejestracyjny
                    </Link>
                    <Link
                        className="nav-link"
                        href={Routes.PublicPasswordReset}
                        prefetch={false}
                    >
                        Zresetuj hasło
                    </Link>
                </nav>
            </div>
            <div className="bottom">
                <div className="bottom-copyright">Copyright © 2022. All Rights Reserved.</div>
                <div className="bottom-links">
                    <Link
                        className="link"
                        href="/regulamin"
                    >
                        Regulamin
                    </Link>
                    <Link
                        className="link"
                        href="/polityka-prywatnosci"
                    >
                        Polityka prywatności
                    </Link>
                </div>
            </div>
        </LayoutContainer>
    </StyledComponent>
));

export default WrapperPublicBlocksFooter;
