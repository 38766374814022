import { FunctionComponent } from 'react';
import classnames from 'classnames';

import StyledComponent from './styles';
import { Props } from './types';

const LayoutTitle: FunctionComponent<Props> = ({ className, title }) => ((
    <StyledComponent className={classnames('layout-title', className)}>
        {title}
    </StyledComponent>
));

export default LayoutTitle;