import { createContext, useState } from 'react';

/**
 *
 * Use only when you want to disabled navigation in your page / component
 *
 * Remember to use with useEffect and return true when component is unmonting
 */
export const HiddenNavContext = createContext(null);

const HiddenNavProvider = ({ children }) => {
    const [state, setState]: [boolean, Function] = useState(false);

    return (
        <HiddenNavContext.Provider value={[ state, setState ]}>
            {children}
        </HiddenNavContext.Provider>
    );
};

export default HiddenNavProvider;